import React, { useState } from "react";
import parse from "html-react-parser";
import Image from "../ResuableImageWrapper";
import VisibilitySensor from "react-visibility-sensor";
import "../../../sass/components/reuseable_components/_image_grid_section.scss";

const BasicImageGrid = (data, { isInView }) => {
  let classList = ["basic-image-grid-section", ""];

  // const removeBottomMargin = data.removeBottomMargin;
  // const notFullWidth = data.notFullWidth;

  // let maxWidth = data?.image?.localFile?.childImageSharp?.fluid?.sizes;
  // maxWidth = maxWidth?.substring(maxWidth.lastIndexOf(" "));

  // if (removeBottomMargin === true) {
  //   classList.push("no-margin");
  // }
  // if (notFullWidth === true) {
  //   classList.push("not-full-width");
  // }

  const [isInview, setIsInview] = useState(false);

  function inviewOnChange(isVisible) {
    setIsInview(isVisible);
  }

  return (
    <VisibilitySensor
      partialVisibility={true}
      offset={{ bottom: 120 }}
      active={!isInview}
      onChange={inviewOnChange}>
      {({ isVisible }) => (
        <section
          className={`${classList.join(" ")} ${isInview ? "is_inview" : ""}`}
          data-bg-shape="show_shape">
          <div className="wrapper-900">
            <div className="grid">
              {console.log(data)}
              {data?.imageGrid?.map((image, index) => {
                console.log(image);
                return (
                  <Image
                    image={image?.image?.localFile?.childImageSharp?.fluid}
                    alt={image?.image?.altText}
                    // maxWidth={notFullWidth ? maxWidth : ""}
                  />
                );
              })}
            </div>
          </div>
        </section>
      )}
    </VisibilitySensor>
  );
};

export default BasicImageGrid;
